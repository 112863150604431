
import { defineComponent } from "vue";
import store from "@/store";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import MultiSelect from "primevue/multiselect";
import WorkOrderDetailsModal from "./WorkOrderDetailsModal.vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Utils from "@/utility/utils";
import { mapActions, mapGetters, mapState } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";

import { PropType } from "vue";
import { WorkOrder } from "@/types/workOrder";
import { WorkCenter } from "@/types/workCenter";

import dayjs from "dayjs";

export default defineComponent({
  components: {
    Button,
    Card,
    Column,
    DataTable,
    WorkOrderDetailsModal,
    InputText,
    Calendar,
    MultiSelect,
  },
  emits: ["openWorkOrder"],
  props: {
    workCenterList: {
      type: Array as PropType<Array<WorkCenter>>,
      required: true,
      default: () => [] as Array<WorkCenter>,
    },
  },
  data() {
    return {
      isResultView: true,
      boardLists: [] as Array<any>,
      showWorkOrderDetailsModal: false,
      selectedOrder: {} as WorkOrder,
      isLoadingOrders: false,
      reqDateFilterValue: "",
      confirmDateFilterValue: "",
      rowsPerPage: 10,
      rows: 10,
      first: 0,
      firstRow: 0,
      page: 1,
      filters: {
        wo_id: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        part_no: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        description: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        req_date: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        rel_date: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        status: {
          value: [],
          matchMode: FilterMatchMode.IN,
        },
        workCenter: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      workOrderStatusListFormated: [],
    };
  },
  computed: {
    ...mapState(["workOrder"]),
    ...mapGetters({
      getWorkOrders: "workorder/getWorkOrders",
      getLength: "workorder/getLength",
      getPage: "workorder/getPage",
      itemsPerPage: "workorder/getItemsPerPage",
      totalRecords: "workorder/getTotalRecords",
      getSortOrder: "workorder/getSortOrder",
      getSortBy: "workorder/getSortBy",
      getWorkOrderStatusItems: "workorder/getWorkOrderStatusItems",
      correlativeExists: "fdict/correlativeExists",
    }),
    showSubassembly(): boolean {
      return this.correlativeExists("WO", "SUBASSEMBLY");
    },
    getSortOrderValue(): number {
      return this.getSortOrder === "DEC" ? -1 : 1;
    },
    getSortByValue(): string {
      return this.getSortBy.replaceAll(".", "_");
    },
  },
  mounted() {
    this.isLoadingOrders = true;
    this.fetchWorkOrders({ Correls: "so_cust_name subassembly" }).then(() => {
      this.isLoadingOrders = false;
    });
    this.first = this.getPage * this.itemsPerPage;
  },
  watch: {
    getWorkOrders() {
      this.formatWorkOrderStatusList();
      if (!this.isResultView) {
        this.formatBoardLists();
      }
    },
    isResultView() {
      if (!this.isResultView) {
        this.formatBoardLists();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchWorkOrders: "workorder/fetchOrders",
      workorderSearch: "workorder/workorderSearch",
      updateWorkOrder: "workorder/updateWorkOrder",
      updateStoredOldOrders: "workorder/updateStoredOldOrders",
    }),
    onPage(event: any): void {
      this.isLoadingOrders = true;
      store.commit("workorder/NEXT_PAGE", event.page);
      store.commit("workorder/SET_RANGE_START", event.first + 1);
      store.commit("workorder/SET_RANGE_END", event.first + event.rows);
      this.workorderSearch({
        Correls: "so_cust_name so_li_req_date so_li_urgent so_li_bom_count subassembly",
      }).finally(() => {
        this.isLoadingOrders = false;
      });
    },
    sortData(event: any) {
      this.isLoadingOrders = true;
      store.commit(
        "workorder/SET_SORT_BY",
        event.sortField.replaceAll("_", "."),
      );
      const sortOrder = event.sortOrder === -1 ? "DEC" : "ASC";
      store.commit("workorder/SET_SORT_ORDER", sortOrder);
      this.workorderSearch({
        Correls: "so_cust_name so_li_req_date so_li_urgent so_li_bom_count subassembly",
      }).finally(() => {
        this.isLoadingOrders = false;
      });
    },
    handleFilter(event: any) {
      this.isLoadingOrders = true;
      store.commit("workorder/NEXT_PAGE", 1);
      store.commit("workorder/SET_RANGE_START", 1);
      store.commit("workorder/SET_RANGE_END", 50);
      store.commit("workorder/SET_FILTER_VALUES", event.filters);
      this.workorderSearch({
        Correls: "so_cust_name so_li_req_date so_li_urgent so_li_bom_count subassembly",
      }).finally(() => {
        this.isLoadingOrders = false;
      });
    },
    formatWorkOrderStatusList() {
      this.workOrderStatusListFormated = Object.keys(
        this.getWorkOrderStatusItems,
      ).reduce((acc: any, key: any) => {
        acc.push({
          id: key,
          label: (this.getWorkOrderStatusItems as any)[key],
        });
        return acc;
      }, []);
    },
    dotColor(workOrder: WorkOrder) {
      if (!workOrder.oper_items || !workOrder.oper_items.length) return;
      const workCenterIndex = this.workCenterList.findIndex(
        (center) =>
          workOrder.oper_items[0] &&
          center.work_center === workOrder.oper_items[0].work_center,
      );
      if (workCenterIndex >= 0) {
        return Utils.getColorByIndex(workCenterIndex);
      }
      return "#BBB";
    },
    statusColor(itemStatus: string) {
      return "#BBB";
    },
    formatBoardLists() {
      let controlStatusList: Array<any> = [];
      controlStatusList = JSON.parse(
        JSON.stringify(this.workOrderStatusListFormated),
      );
      controlStatusList.unshift({ id: "", label: "Unassigned" });
      controlStatusList.forEach((element: any) => (element.items = []));
      this.getWorkOrders.forEach((item: any) => {
        const statusIndex = controlStatusList.findIndex(
          (element) => element.id === item.status,
        );
        if (statusIndex >= 0) {
          controlStatusList[statusIndex].items.push(item);
        } else {
          controlStatusList[0].items.push(item);
        }
      });
      this.boardLists = controlStatusList;
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event;
    },
    rowClick(item: { data: WorkOrder }) {
      this.$emit("openWorkOrder", item.data);
    },
    closeWorkOrderDetailsModal(isVisible: boolean) {
      this.showWorkOrderDetailsModal = isVisible;
    },
    onStatusChange(event: any) {
      console.log(event);
    },
    getWorkCenter(workOrder: WorkOrder) {
      if (!workOrder.oper_items || !workOrder.oper_items.length) return;
      const workCenter = this.workCenterList.find(
        (center) =>
          workOrder.oper_items[0] &&
          center.work_center === workOrder.oper_items[0].work_center,
      );
      return workCenter ? workCenter.description : "";
    },
    dateFilterChange(event: Date, type: "reqDate" | "confirmDate") {
      if (type === "reqDate") {
        this.reqDateFilterValue = dayjs(event).format("MM/DD/YYYY");
      } else {
        this.confirmDateFilterValue = dayjs(event).format("MM/DD/YYYY");
      }
    },
    applyDateFilter(type: "reqDate" | "confirmDate") {
      if (type === "reqDate") {
        this.filters.req_date.value = dayjs(this.reqDateFilterValue).format(
          "MM/DD/YYYY",
        );
      } else {
        this.filters.rel_date.value = dayjs(this.confirmDateFilterValue).format(
          "MM/DD/YYYY",
        );
      }
    },
    clearDateFilter(type: "reqDate" | "confirmDate") {
      if (type === "reqDate") {
        this.reqDateFilterValue = "";
        this.filters.req_date.value = "";
      } else {
        this.confirmDateFilterValue = "";
        this.filters.rel_date.value = "";
      }
    },
    async saveWorkOrder(order: WorkOrder, workCenter: string) {
      const oldRecord = JSON.parse(JSON.stringify(this.selectedOrder));
      const orderToSave = JSON.parse(JSON.stringify(order));

      if (orderToSave.oper_items && orderToSave.oper_items.length) {
        orderToSave.oper_items.forEach((item: any) => {
          item.work_center = workCenter;
        });
      }
      await this.updateWorkOrder({ order: orderToSave, oldRecord });
      await this.updateStoredOldOrders().then(() => {
        this.closeWorkOrderDetailsModal(false);
      });
    },
  },
});
